import { ComponentPropsWithoutRef, FC } from 'react'
import styled from 'styled-components'

const InputWrapper = styled.div`
	position: relative;
	width: 100%;
	height: 300px;
`
const Label = styled.label`
	color: #9ab0ff85;
	position: absolute;
	left: 0;
	top: 0.5rem;
	font-weight: 500;
	font-size: 16px;
	cursor: text;
	transition: top 200ms ease-in, left 200ms ease-in, font-size 200ms ease-in;
`

const TextareaStyles = styled.textarea`
	position: absolute;
	top: 0;
	left: 0;
	border: none;
	border-bottom: 2px solid #9ab0ff85;
	font-weight: 500;
	font-size: 16px;
	outline: none;
	width: 100%;
	height: 300px;
	background: none;
	&::placeholder {
		color: #9ab0ff85;
		font-weight: 500;
		font-size: 16px;
		line-height: 180%;
	}
	&:hover {
		border-color: #2755fc85;
	}
	&:focus {
		border-color: #2755fc85;
		& ~ label {
			top: -0.6rem;
			font-size: 0.8rem;
			left: 0.1rem;
			color: #2755fc85;
		}
	}
	&:not(:placeholder-shown)&:not(:focus) ~ label {
		top: -0.6rem;
		font-size: 0.8rem;
		left: 0.1rem;
		color: #9ab0ff85;
	}
`

interface ComponentProps {
	id: string
	label?: string
}
export type TextareaProps = ComponentProps & ComponentPropsWithoutRef<'textarea'>

const Textarea: FC<TextareaProps> = ({ label, defaultValue, id, ...textareaAttrs }) => {
	return (
		<InputWrapper>
			<TextareaStyles id={id} defaultValue={defaultValue} placeholder='' {...textareaAttrs} />
			{label && <Label htmlFor={id}>{label}</Label>}
		</InputWrapper>
	)
}

export default Textarea
