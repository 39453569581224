import { useEffect } from 'react'
import { useAppDispatch } from '../../hooks'
import { ArticlesTable } from '../../components'
import { articleActions } from '../../store/slices/articleSlice'

const Articles = () => {
	const dispatch = useAppDispatch()

	useEffect(() => {
		dispatch(articleActions.fetchAllArticles())
	}, [])

	return <ArticlesTable />
}

export default Articles
