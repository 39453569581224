import { PayloadAction, createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { Article, getAllArticles } from '../../services/articles'

const fetchAllArticles = createAsyncThunk('articles', async () => {
	const articles = (await getAllArticles()).data
	return articles
})

export interface ArticleState {
	data: Article[]
}

const initialState: ArticleState = {
	data: [],
}

const articleSlice = createSlice({
	name: 'article',
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchAllArticles.fulfilled, (state, action: PayloadAction<Article[]>) => {
			state.data = action.payload
		})
	},
})

export const articleActions = {
	...articleSlice.actions,
	fetchAllArticles,
}
export default articleSlice.reducer
