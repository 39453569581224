import { api } from './config'

export interface RobloxUser {
	id: number
	createdAt: string
	updatedAt: string
	nickname: string
	robloxId: number
	balance: number
	refGift: number
	award: number
	tickets: number
}

type UpdateRobloxUser = Pick<RobloxUser, 'balance'>

export const findAllRobloxUsers = () => api.get('roblox/users')
export const clearGiveawayTickets = () => api.patch('roblox/users/giveaway/tickets/clear')
export const updateRobloxUser = (id: number, data: UpdateRobloxUser) =>
	api.patch(`roblox/user/${id}`, data)
export const checkRobloxUserNickname = (nickname: string) =>
	api.get(`roblox/nickname/check/${nickname}`)
