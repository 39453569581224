import { api } from './config'

export interface Article {
	id: string
	createdAt: Date
	updatedAt: Date
	url: string
	title: string
	description: string
	keywords: string
	name: string
	icon: string
	banner: string
	text: string
	tags: string[]
	isActive: boolean
}

export interface ArticleCreate
	extends Omit<Article, 'id' | 'createdAt' | 'updatedAt' | 'icon' | 'banner' | 'tags'> {
	tags?: string
	icon?: File
	banner?: File
}

export interface ArticleUpdate extends Partial<Omit<Article, 'id' | 'icon' | 'banner' | 'tags'>> {
	tags?: string
	icon?: File
	banner?: File
}

export const getAllArticles = () => api.get('/article/all')
export const createArticle = (data: FormData) => api.post('/article', data)
export const updateArticle = (id: string, data: FormData) => api.patch(`/article/${id}`, data)
export const deleteArticle = (id: string) => api.delete(`/article/${id}`)
