import { Loader, Table, TableCell, TableCellMobile, TableHeader } from '../ui'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { uiActions } from '../../store/slices/uiSlice'
import { PromoTabelRow } from './PromoTabelRow'
import { promoActions } from '../../store/slices/promoSlice'
import { toast } from 'react-toastify'

const headers = ['ID', 'Имя', 'Условие', 'Награда', 'Активность', 'Видимость', '📈', 'Действия']
const mobileHeader = ['Активность', 'Видимость', '📈']

const PromoTabel = () => {
	const promocodes = useAppSelector((state) => state.promo.promocodes)
	const mobile = useAppSelector((state) => state.screen.mobile)
	const dispatch = useAppDispatch()

	const changeHandler = async (id: number) => dispatch(uiActions.setPromocodesModalIndexState(id))
	const deleteHandler = async (id: number) => {
		const res = await dispatch(promoActions.removePromocode(id))
		if (res.meta.requestStatus === 'fulfilled') return toast('Промокод успешно удален')
		return toast(res.payload as string, { type: 'error' })
	}

	const header = (
		<TableHeader>
			{headers.map((header, idx) => {
				if (mobileHeader.includes(header))
					return <TableCellMobile key={idx}>{header}</TableCellMobile>
				return <TableCell key={idx}>{header}</TableCell>
			})}
		</TableHeader>
	)

	const rows = promocodes.map((promocode, idx) => {
		return (
			<PromoTabelRow
				key={idx}
				promocode={promocode}
				onChange={() => changeHandler(idx)}
				onDelete={() => promocode.id && deleteHandler(promocode.id)}
			/>
		)
	})

	if (promocodes.length === 0) return <Loader />

	return (
		<Table
			rows={rows}
			header={header}
			columns={mobile ? headers.length - mobileHeader.length : headers.length}
			enablePagination
		/>
	)
}

export default PromoTabel
