import { Loader, Table, TableCell, TableHeader } from '../ui'
import { useAppDispatch, useAppSelector } from '../../hooks'
import { ArticlesTableRow } from './ArticlesTabelRow'
import { ArticleUpdate, createArticle, deleteArticle, updateArticle } from '../../services'
import { articleActions } from '../../store/slices/articleSlice'
import { toast } from 'react-toastify'

const headers = ['ID', 'Дата', 'Название', 'Действия']

const ArticlesTable = () => {
	const articles = useAppSelector((state) => state.article.data)
	const dispatch = useAppDispatch()

	const onEnd = async (status: number, statusText: string) => {
		if (status === 200 || status === 201) {
			toast('Успешно')
			return await dispatch(articleActions.fetchAllArticles())
		}
		return toast(statusText, { type: 'error' })
	}

	const createHandler = async (article: ArticleUpdate) => {
		const formData: FormData = new FormData()
		Object.entries(article).map(([key, value]) => formData.append(key, value))
		const res = await createArticle(formData)
		return await onEnd(res.status, res.statusText)
	}

	const updateHandler = async (id: string, article: ArticleUpdate) => {
		const formData: FormData = new FormData()
		Object.entries(article).map(([key, value]) => formData.append(key, value))
		const res = await updateArticle(id, formData)
		return await onEnd(res.status, res.statusText)
	}

	const deleteHandler = async (id: string) => {
		const res = await deleteArticle(id)
		return await onEnd(res.status, res.statusText)
	}

	const header = (
		<TableHeader>
			{headers.map((header, idx) => {
				return <TableCell key={idx}>{header}</TableCell>
			})}
		</TableHeader>
	)
	const rows = articles.map((article, idx) => (
		<ArticlesTableRow
			key={idx}
			article={article}
			onCreate={createHandler}
			onUpdate={updateHandler}
			onDelete={deleteHandler}
		/>
	))
	rows.unshift(
		<ArticlesTableRow
			key={-1}
			onCreate={createHandler}
			onUpdate={updateHandler}
			onDelete={deleteHandler}
		/>
	)

	if (articles.length === 0) return <Loader />

	return <Table rows={rows} header={header} columns={headers.length} enablePagination />
}

export default ArticlesTable
