import { api } from './config'

export interface Settings {
	minRobuxBuy: number
	maxRobuxBuy: number
	rate: number
	payTime: number
	robuxAward: number
	awardCondition: number
	withdrawal: number
	competitor?: boolean
}

export interface GiveawaySettings {
	startDate: Date
	finishDate: Date
	prizeFund: number
	winning: number
}

export const getSettings = () => api.get<Settings>('settings')
export const getGiveawaySettings = () => api.get<GiveawaySettings>('giveaway-settings')

export const updateSettings = (settings: Settings) => api.post('settings/update', settings)
export const updateGiveawaySettings = (settings: GiveawaySettings) =>
	api.patch('giveaway-settings', settings)

export const switchCompetitor = () => api.post('tasks/competitor/switch')
