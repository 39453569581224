import { FC, useState } from 'react'

import { TableRow, TableCell, TableCellMobile, Header, Input, Select, Textarea } from '../ui'
import { formatDate } from '../../helpers'
import { Form, InputsWrapper, Submit, TableRowMessage } from './styles'
import { Article, ArticleUpdate, baseURL } from '../../services'

interface ArticlesTableRowProps {
	article?: Article
	onCreate: (data: ArticleUpdate) => void
	onUpdate: (id: string, data: ArticleUpdate) => void
	onDelete: (id: string) => void
}

export const ArticlesTableRow: FC<ArticlesTableRowProps> = ({
	article,
	onCreate,
	onUpdate,
	onDelete,
}) => {
	const [open, setOpen] = useState(false)
	const [url, setUrl] = useState(article !== undefined ? article.url : '')
	const [title, setTitle] = useState(article !== undefined ? article.title : '')
	const [description, setDescription] = useState(article !== undefined ? article.description : '')
	const [keywords, setKeywords] = useState(article !== undefined ? article.keywords : '')
	const [name, setName] = useState(article !== undefined ? article.name : '')
	const [tags, setTags] = useState(article !== undefined ? article.tags.join(',') : '')
	const [icon, setIcon] = useState<File | undefined>(undefined)
	const [banner, setBanner] = useState<File | undefined>(undefined)
	const [isActive, setIsActive] = useState(
		article !== undefined ? article.isActive.toString() : 'false'
	)
	const [text, setText] = useState(article !== undefined ? article.text : '')

	const changeSelectHandelr: React.ChangeEventHandler<HTMLSelectElement> = ({
		target: { value },
	}) => setIsActive(value)
	const changeTextareaHandelr: React.ChangeEventHandler<HTMLTextAreaElement> = ({
		target: { value },
	}) => setText(value)
	const changeFileHandler: React.ChangeEventHandler<HTMLInputElement> = ({
		target: { name, files },
	}) => {
		if (files !== null) {
			if (name === 'icon') setIcon(files[0])
			if (name === 'banner') setBanner(files[0])
		}
	}
	const changeHandler: React.ChangeEventHandler<HTMLInputElement> = ({
		target: { name, value },
	}) => {
		if (name === 'url') setUrl(value)
		if (name === 'title') setTitle(value)
		if (name === 'description') setDescription(value)
		if (name === 'keywords') setKeywords(value)
		if (name === 'name') setName(value)
		if (name === 'tags') setTags(value)
		if (name === 'isActive') setIsActive(value)
		if (name === 'text') setText(value)
	}

	const submit: React.MouseEventHandler<HTMLButtonElement> = (e) => {
		e.preventDefault()
		const data: ArticleUpdate = {}
		if (url !== '') data['url'] = url
		if (title !== '') data['title'] = title
		if (description !== '') data['description'] = description
		if (keywords !== '') data['keywords'] = keywords
		if (name !== '') data['name'] = name
		if (tags !== '') data['tags'] = tags
		if (isActive !== '') data['isActive'] = isActive === 'true' ? true : false
		if (text !== '') data['text'] = text
		if (icon !== undefined) data['icon'] = icon
		if (banner !== undefined) data['banner'] = banner
		if (article !== undefined) return onUpdate(article.id, data)
		return onCreate(data)
	}

	return (
		<>
			<TableRow>
				<TableCell>{article?.id.split('-')[0]}</TableCell>
				<TableCellMobile>{article ? formatDate(article.createdAt) : ''}</TableCellMobile>
				<TableCell>{article?.name}</TableCell>
				<TableCell>
					<span
						style={{ cursor: 'pointer', marginRight: '15px' }}
						onClick={() => setOpen((prev) => !prev)}
					>
						💾
					</span>
					{article && (
						<span style={{ cursor: 'pointer' }} onClick={() => onDelete(article.id)}>
							❌
						</span>
					)}
				</TableCell>
			</TableRow>
			{open && (
				<TableRowMessage>
					<Form>
						<Header>Редактирование статьи</Header>
						<InputsWrapper>
							<Input
								label='URL'
								id='url'
								name='url'
								value={url}
								onChange={changeHandler}
							/>
							<Input
								label='Title'
								id='title'
								name='title'
								value={title}
								onChange={changeHandler}
							/>
							<Input
								label='Description'
								id='description'
								name='description'
								value={description}
								onChange={changeHandler}
							/>
							<Input
								label='Keywords'
								id='keywords'
								name='keywords'
								value={keywords}
								onChange={changeHandler}
							/>
							<Input
								label='Имя'
								id='name'
								name='name'
								value={name}
								onChange={changeHandler}
							/>
							<Input
								label='Теги'
								id='tags'
								name='tags'
								value={tags}
								onChange={changeHandler}
							/>
							{article?.icon && (
								<img src={`${baseURL}file/articles/${article.icon}`} alt='Иконка' />
							)}
							<input type='file' name='icon' id='icon' onChange={changeFileHandler} />
							{article?.banner && (
								<img
									src={`${baseURL}file/articles/${article?.banner}`}
									alt='Баннер'
								/>
							)}
							<input
								type='file'
								name='banner'
								id='banner'
								onChange={changeFileHandler}
							/>
							<Select
								label='Активность'
								id='isActive'
								value={isActive}
								onChange={changeSelectHandelr}
							>
								{['true', 'false'].map((value) => (
									<option key={value} value={value}>
										{value}
									</option>
								))}
							</Select>
							<Textarea
								label='Текст'
								id='text'
								name='text'
								value={text}
								onChange={changeTextareaHandelr}
							/>
						</InputsWrapper>
						<Submit onClick={(e) => submit(e)}>
							{article ? 'Сохранить' : 'Создать'}
						</Submit>
					</Form>
				</TableRowMessage>
			)}
		</>
	)
}
