import { MouseEvent, useEffect, useState } from 'react'
import { toast } from 'react-toastify'

import { useAppDispatch, useAppSelector } from '../../hooks'
import { Header, Input } from '../ui'
import { uiActions } from '../../store/slices/uiSlice'
import { Form, Submit } from './styles'
import Modal from '../Modal/Modal'
import { InputsWrapper } from './styles'
import { clearGiveawayTickets, getGiveawaySettings, updateGiveawaySettings } from '../../services'
import { AxiosError } from 'axios'
import { extractErrorMessage } from '../../helpers'

const GiveawaySettingsModal = () => {
	const isOpen = useAppSelector((state) => state.ui.giveawaySettingsModalIsOpen)

	const [startDate, setStartDate] = useState<Date | string>('')
	const [finishDate, setFinishDate] = useState<Date | string>('')
	const [prizeFund, setPrizeFund] = useState<number | string>('')
	const [winning, setWinning] = useState<number | string>('')

	const dispatch = useAppDispatch()

	useEffect(() => {
		if (isOpen) {
			getGiveawaySettings().then(({ data }) => {
				setStartDate(data.startDate)
				setFinishDate(data.finishDate)
				setPrizeFund(data.prizeFund)
				setWinning(data.winning)
			})
		}
	}, [isOpen])

	const closeHandler = () => {
		dispatch(uiActions.setGiveawaySettingsModalState(false))
	}

	const clearGiveawayTicketsHandler = async (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()

		try {
			await clearGiveawayTickets()
			toast('Данные сохранены', { type: 'success' })
		} catch (error) {
			if (error instanceof AxiosError) {
				const errorMessage = extractErrorMessage(error)
				toast(errorMessage, { type: 'error' })
			}
		}
	}

	const updateGiveawaySettingsHandler = async (e: MouseEvent<HTMLButtonElement>) => {
		e.preventDefault()

		try {
			await updateGiveawaySettings({
				startDate: startDate as Date,
				finishDate: finishDate as Date,
				prizeFund: +prizeFund,
				winning: +winning,
			})
			toast('Данные сохранены', { type: 'success' })
		} catch (error) {
			if (error instanceof AxiosError) {
				const errorMessage = extractErrorMessage(error)
				toast(errorMessage, { type: 'error' })
			}
		}
	}

	if (!isOpen) return null

	return (
		<Modal close={closeHandler}>
			<Form>
				<Header>Настройки розыгрыша</Header>
				<InputsWrapper>
					<Input
						label='Дата начала'
						id='startDate'
						value={startDate.toString()}
						onChange={(e) => setStartDate(e.target.value)}
					/>
					<Input
						label='Дата завершения'
						id='finishDate'
						value={finishDate.toString()}
						onChange={(e) => setFinishDate(e.target.value)}
					/>
					<Input
						label='Призовой фонд'
						id='prizeFund'
						value={prizeFund}
						onChange={(e) => setPrizeFund(e.target.value)}
					/>
					<Input
						label='Выигрыш'
						id='winning'
						value={winning}
						onChange={(e) => setWinning(e.target.value)}
					/>
				</InputsWrapper>
				<Submit onClick={(e) => clearGiveawayTicketsHandler(e)}>
					Сбросить статистику билетов
				</Submit>
				<Submit onClick={(e) => updateGiveawaySettingsHandler(e)}>Сохранить</Submit>
			</Form>
		</Modal>
	)
}

export default GiveawaySettingsModal
