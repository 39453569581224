import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { deletePromocode, getAllPromocodes, Promocode } from '../../services'

const fetchPromocodes = createAsyncThunk('promocodes/fetchPromocodes', async () => {
	const data = (await getAllPromocodes()).data
	return data
})

const removePromocode = createAsyncThunk('promocode/delete', async (id: number) => {
	await deletePromocode(id)
	return id
})

export interface PromoState {
	promocodes: Promocode[]
	types: string[]
}

const initialState: PromoState = { promocodes: [], types: [] }

const promoSlice = createSlice({
	name: 'promocode',
	initialState: initialState,
	reducers: {},
	extraReducers: (builder) => {
		builder.addCase(fetchPromocodes.fulfilled, (state, { payload }) => {
			state.promocodes = payload.promocodes
			state.types = payload.types
		})
		builder.addCase(removePromocode.fulfilled, (state, { payload }) => {
			const index = state.promocodes.findIndex((elem) => elem.id === payload)
			if (index !== undefined && index !== -1) state.promocodes.splice(index, 1)
		})
	},
})

export const promoActions = {
	...promoSlice.actions,
	fetchPromocodes,
	removePromocode,
}
export default promoSlice.reducer
